.grid-2 {
  width: 100%;
  display: grid;
  //   grid-template-areas: "profile controls";
  //   grid-template-columns: 1fr 1fr;
  grid-template: "profile controls" 1fr / min-content 1fr;
  padding: 1rem;
}

.profile {
  grid-area: profile;

  .profile-box {
    width: 400px;
    height: 550px;
    @include box;
    svg {
      color: $icons;
    }
    .profile-avatar {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 2rem;
    }
    .name {
      width: 100%;
      text-align: center;
      margin-top: 1rem;
      color: $name;
    }
    .location {
      @include profile;
    }
    .member {
      @include profile;
    }
    .phone {
      @include profile;
      margin-bottom: 1rem;
      margin-left: 0;
    }
    .edit_btn {
      @include button;
      span {
        margin-right: 1rem;
      }
    }
  }
}

.content-b {
  h4,
  h5 {
    color: $name;
  }
}
.controls {
  grid-area: controls;

  @include box;
  .sub-header {
    margin-bottom: 1rem;
    ul {
      display: flex;

      li:first-child {
        padding-right: 2rem;
      }
      li {
        font-size: 0.8rem;

        cursor: pointer;
        svg {
          color: $icons;
        }
        span {
          margin-left: 0.3rem;
        }
      }
    }
  }
  .btns {
    @include button;
    max-width: 200px;
    height: 50px;
    margin: 0;
  }
}

.accounts {
  .modal-box {
    height: 450px;
    .btns {
      @include button;
      max-width: 200px;
      height: 50px;
      margin: 1rem 0 0 0;
    }
  }
}

@media screen and (max-width: 1024px) {
  .grid-2 {
    width: 100%;
    display: block;
    padding: 0.3rem;
    margin-top: 1rem;
  }

  .profile {
    width: 100%;
    display: flex;
    justify-content: center;

    .profile-box {
      max-width: 400px;
      min-width: 315px;
      height: 550px;
      @include box;
      svg {
        color: $icons;
      }
      .profile-avatar {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 2rem;
      }
      .name {
        width: 100%;
        text-align: center;
        margin-top: 1rem;
        color: $name;
      }
      .location {
        @include profile;
      }
      .member {
        @include profile;
      }
      .phone {
        @include profile;
        margin-bottom: 1rem;
        margin-left: 0;
      }
      .edit_btn {
        @include button;
        span {
          margin-right: 1rem;
        }
      }
    }
  }

  .content-b {
    h4,
    h5 {
      color: $name;
    }
  }
  .controls {
    @include box;
    margin-top: 1rem;
    margin-bottom: 5rem;
    .sub-header {
      margin-bottom: 1rem;
      ul {
        display: flex;

        li:first-child {
          padding-right: 2rem;
        }
        li {
          font-size: 0.8rem;

          cursor: pointer;
          svg {
            color: $icons;
          }
          span {
            margin-left: 0.3rem;
          }
        }
      }
    }
    .btns {
      @include button;
      max-width: 200px;
      height: 50px;
      margin: 0;
    }
  }

  .accounts {
    .modal-box {
      height: 450px;
      .btns {
        @include button;
        max-width: 200px;
        height: 50px;
        margin: 1rem 0 0 0;
      }
    }
  }
}
