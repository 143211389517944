.services {
  display: grid;
  grid-template: "side main" 1fr / min-content 1fr;
}
.mobile-services {
  display: none;
}

.side {
  grid-area: side;

  height: 80vh;
  width: 220px;
  padding: 1rem;
  .date-pick {
    margin-bottom: 1rem;
  }

  .due {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    h5 {
      margin-bottom: 0;
    }
    h3 {
      margin-bottom: 0;
      margin-right: 1rem;
    }

    .pending {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: $warning;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    div {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: $buttons;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.service-main {
  grid-area: main;
  padding: 1rem;
}

.react-date-picker__wrapper {
  border: none !important;
  border-bottom: thin solid rgb(218, 218, 218) !important;
}

@media screen and (max-width: 767px) {
  .services {
    display: block;
  }
  .side {
    display: none;
  }
  .mobile-services {
    display: block;
    margin-top: 2rem;
    padding: 1rem;
    .status-services {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      .read-out {
        display: flex;
        padding-right: 2rem;
        align-items: center;
        svg {
          margin-right: 1rem;
        }
        .pending {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: $warning;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        div {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: $buttons;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .b-filter {
        svg {
          color: $buttons;
          font-size: x-large;
        }
      }
    }
  }
}
