.container {
  width: 100%;
  display: grid;
  grid-template-areas: "sidebar main";
  grid-template-columns: 200px auto;
  margin-bottom: 1rem;
}
.bar {
  grid-area: sidebar;
  height: calc(100vh - 200px);
  padding: 1rem;
}

.customers {
  grid-area: main;
  //height: calc(100vh - 200px);
  height: 100%;

  padding: 1rem;
  .loader-content {
    height: calc(100vh - 400px) !important;
  }
}
.customer-panel {
  width: 100%;
  height: 100px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 8px;
  .customer {
    display: flex;
    align-items: center;
    flex: 1;
  }
  .avatar {
    margin-right: 1rem;
    svg {
      color: $buttons;
    }
  }

  .subtitle {
    flex: 1;
    text-align: left;
  }
  .phone {
    font-size: 14px;
  }
  .customer-services {
    display: flex;
    svg {
      color: $icons;
    }
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  div:first-child {
    margin-right: 2rem;
  }
  div {
    @include button;
    width: 100px;
    margin: 0;
    height: 50px;
  }
}

@media (max-width: 768px) {
  .container {
    width: 100%;
    display: block;

    margin-bottom: 1rem;
  }
  .bar {
    // height: calc(100vh - 200px);

    padding: 0.4rem;
    height: 100%;

    hr {
      display: none;
    }
  }

  .customers {
    padding: 0.4rem;
    .loader-content {
      height: calc(100vh - 400px) !important;
    }
  }
  .customer-panel {
    width: 100%;
    height: 100px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    margin-bottom: 0.5rem;
    border-radius: 8px;
    .customer {
      display: flex;
      align-items: center;
      flex: 1;
    }
    .avatar {
      margin-right: 1rem;
      svg {
        color: $buttons;
      }
    }

    .subtitle {
      flex: 1;
      text-align: left;
      display: none;
    }

    .title {
      font-size: 14px;
    }
    .phone {
      font-size: 14px;
    }
    .customer-services {
      display: flex;
      svg {
        color: $icons;
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    div:first-child {
      margin-right: 2rem;
    }
    div {
      @include button;
      width: 100px;
      margin: 0;
      height: 50px;
    }
  }
}
