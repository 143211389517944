$buttons: #7bb6cf;
$warning: #eb5e55;
$errors: #f12c1e;
$complete: #61c9a8;
$nav_links: rgb(66, 66, 66);
$icons: #9dd2e9;
$name: rgb(73, 73, 73);
$large_numbers: rgb(94, 94, 94);
$text: rgb(43, 43, 43);

$test: #407992;
