.loader-content {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: $buttons;
  }
}
