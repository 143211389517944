.content-overview {
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: 1fr 1fr;
  gap: 30px 70px;
  grid-template-areas:
    "jobs side"
    "details side";
  height: calc(100vh - 150px);
  margin-top: 2.5rem;
}

.mobile-stats {
  display: none;
}

.details {
  grid-area: details;
  width: 100%;

  .details-3 {
    display: flex;
    width: 100%;
    justify-content: center;
    // margin-top: 4rem;

    .detail {
      min-width: 200px;
      background-color: white;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
      border-radius: 30px;
      height: 270px;
      display: flex;
      justify-content: center;
      margin-right: 2rem;
      margin-left: 1rem;
      .content-detail {
        margin-top: 1.5rem;
        text-align: center;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .avatar {
          text-align: center;
          svg {
            color: $buttons;
          }
        }
        div {
          margin-top: 0.5rem;
        }
        .cus-name {
          font-weight: 700;
        }
        .sub-title {
          font-size: 14px;
          color: rgb(109, 109, 109);
        }
        .large-title {
          font-size: 60px;
          color: rgb(109, 109, 109);
        }

        .detail-btn {
          cursor: pointer;
          background: $buttons;
          padding: 0.8rem;
          border-radius: 30px;
          color: white;
          width: 150px;
          margin-top: 1rem;
        }
      }
    }
  }
}
.jobs {
  grid-area: jobs;
  width: 100%;

  .list {
    min-height: 370px;
  }
}

.right-side {
  grid-area: side;
  width: 300px;
  .stats-box {
    width: 100%;
    background-color: white;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    border-radius: 30px;

    padding: 1rem;
    .stats {
      margin: 0 0 2rem 1.5rem;
      width: 100%;

      .icon {
        @include circle(30px, 30px);
      }
      .stats-data {
        display: flex;

        .stats-number {
          font-size: 3.6rem;
          color: $large_numbers;
          margin-right: 0.5rem;
        }
      }
      .stats-name {
        width: 100%;
        font-weight: 700;
        font-size: 0.8rem;
        color: $name;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .content-overview {
    height: calc(100vh - 150px);
    margin-top: 2.5rem;
    padding: 0.9rem;
    display: block;
    position: relative;
  }
  .mobile-stats {
    display: block;
    width: 100%;
    .row-mobile {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      margin-bottom: 1.5rem;
      .stats {
        text-align: center;
        @include box;
        min-width: 122px;
        max-width: 130px;
        border-radius: 20px;

        svg {
          color: $buttons;
        }
        .stats-number {
          font-size: 30px;
        }
      }
    }
  }

  .details {
    display: none;
  }
  .jobs {
    grid-area: jobs;
    width: 100%;

    .list {
      min-height: 370px;
    }
  }

  .right-side {
    display: none;

    .stats-box {
      width: 100%;
      background-color: white;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
      border-radius: 30px;

      padding: 1rem;
      .stats {
        margin: 0 0 2rem 1.5rem;
        width: 100%;

        .icon {
          @include circle(30px, 30px);
        }
        .stats-data {
          display: flex;

          .stats-number {
            font-size: 3.6rem;
            color: $large_numbers;
            margin-right: 0.5rem;
          }
        }
        .stats-name {
          width: 100%;
          font-weight: 700;
          font-size: 0.8rem;
          color: $name;
        }
      }
    }
  }
}
@media screen and (max-width: 420px) {
  .content-overview {
    height: calc(100vh - 150px);
    margin-top: 2.5rem;
    padding: 0.9rem;
    display: block;
    position: relative;
  }
  .mobile-stats {
    display: block;
    width: 100%;
    .row-mobile {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      margin-bottom: 1.5rem;
      .stats {
        text-align: center;
        @include box;
        min-width: 105px;
        max-width: 110px;
        border-radius: 20px;

        svg {
          color: $buttons;
        }
        .stats-number {
          font-size: 30px;
        }
      }
    }
  }

  .details {
    display: none;
  }
  .jobs {
    grid-area: jobs;
    width: 100%;

    .list {
      min-height: 370px;
    }
  }

  .right-side {
    display: none;

    .stats-box {
      width: 100%;
      background-color: white;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
      border-radius: 30px;

      padding: 1rem;
      .stats {
        margin: 0 0 2rem 1.5rem;
        width: 100%;

        .icon {
          @include circle(30px, 30px);
        }
        .stats-data {
          display: flex;

          .stats-number {
            font-size: 3.6rem;
            color: $large_numbers;
            margin-right: 0.5rem;
          }
        }
        .stats-name {
          width: 100%;
          font-weight: 700;
          font-size: 0.8rem;
          color: $name;
        }
      }
    }
  }
}
