.modal-box {
  @include box;
  position: relative;

  min-width: 320px;
  //height: 650px;

  .modal-action {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: -30px;

    .btn-cancel {
      // text-align: right;
      color: white;
      width: 80px;
      span {
        padding-left: 0.5rem;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .modal-box {
    @include box;
    position: relative;

    min-width: 320px;

    //height: 650px;

    .modal-action {
      width: 100%;
      margin-top: 2rem;
      display: flex;
      justify-content: flex-end;
      position: absolute;
      bottom: -30px;

      .btn-cancel {
        // text-align: right;
        color: white;
        width: 80px;
        span {
          padding-left: 0.5rem;
        }
      }
    }
  }
}
