.toolbar {
  height: 70px;
  background: white;
  width: 100%;
  box-shadow: 0px 1px 30px 1px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  overflow: visible;
  border-radius: 2px;
  padding: 1rem;
  .tool-bar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-toolbar {
      display: flex;
      align-items: center;
      flex: 1.2;
      .mobile-logo {
        display: none;
      }
      .mobile-menu {
        display: none;
      }

      .logo {
        text-transform: uppercase;
        padding-right: 2rem;
        span {
          color: $buttons;
        }
      }
      .search {
        width: 225px;
        position: relative;

        .icon {
          position: absolute;
          top: 5px;
          right: 15px;
          color: gray;
        }
        input {
          outline: none;
          width: 100%;
          height: 2rem;
          padding: 0.8rem;
          background: rgb(247, 247, 247);
          border-radius: 20px;
          border: 2px solid #eee;
          color: rgb(95, 95, 95);
        }
        .error {
          border: 2px solid rgb(211, 95, 80);
        }
        .good {
          border: 2px solid $buttons;
        }
      }
      .icon {
        padding-left: 1rem;
        display: block;
      }
    }

    .tool-items {
      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        li {
          padding-right: 1rem;
          display: flex;
          align-items: center;
          svg {
            font-size: large;
            color: $nav_links;
          }
          a {
            color: $nav_links;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .toolbar {
    .tool-bar-content {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      width: 100%;
      .left-toolbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .mobile-logo {
          display: block;
          font-size: 25px;
          padding-right: 0.3rem;
          span {
            color: $buttons;
          }
        }
        .logo {
          display: none;
        }

        .mobile-menu {
          display: block;
          text-align: right;
          width: 30px;

          svg {
            color: $buttons;
            font-size: 25px;
          }
        }

        .logo {
          text-transform: uppercase;
          padding-right: 2rem;
          width: 100%;
          span {
            color: $buttons;
          }
        }
        .search {
          display: block;
          position: relative;
          width: 70%;

          .icon {
            position: absolute;
            top: 5px;
            right: 15px;
            color: gray;
          }
          input {
            outline: none;
            width: 100%;
            height: 2rem;
            padding: 0.8rem;
            background: rgb(247, 247, 247);
            border-radius: 20px;
            border: 2px solid #eee;
            color: rgb(95, 95, 95);
          }
          .error {
            border: 2px solid rgb(211, 95, 80);
          }
          .good {
            border: 2px solid $buttons;
          }
        }
        .icon {
          padding-left: 1rem;
          display: block;
        }
      }

      .tool-items {
        display: none;
        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;
          li {
            padding-right: 1rem;
            display: flex;
            align-items: center;
            svg {
              font-size: large;
              color: $nav_links;
            }
            a {
              color: $nav_links;
            }
          }
        }
      }
    }
  }
}
