// .left-slide-menu {
//   display: none;
// }

.left-slide-menu {
  @include box;
  position: fixed;
  width: 250px;
  height: 100vh;
  top: 71px;
  right: 0;
  z-index: 100000;
  display: block;
  background: $buttons;
  color: white;
  .menu-content {
    color: white;
    li {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 1rem 0 1rem 1rem;
      list-style: none;
      height: 60px;
      font-size: 18px;
      a {
        display: block;
        width: 100%;
        color: white;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  // .left-slide-menu {
  //   @include box;
  //   position: fixed;
  //   width: 200px;
  //   height: 100vh;
  //   top: 71px;
  //   right: 0;
  //   z-index: 100000;
  // }
}
