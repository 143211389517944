//mixins

@mixin profile {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  font-size: 14px;
  span {
    margin-left: 0.3rem;
  }
}
@mixin box {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  overflow: visible;
  border-radius: 2px;
  padding: 1rem;
}
.back {
  margin-left: 2rem;
  cursor: pointer;
}

.content {
  display: flex;
  width: 100%;
  height: calc(100vh - 100px);

  .profile {
    flex: 0.5;
    padding: 2rem;
    .profile-box {
      width: 400px;
      height: 550px;
      @include box;
      .profile-avatar {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 2rem;
      }
      .name {
        width: 100%;
        text-align: center;
        margin-top: 1rem;
      }
      .location {
        @include profile;
        color: gray;
      }
      .member {
        @include profile;
        color: gray;
      }
      .phone {
        @include profile;
      }
      .edit_btn {
        @include button;
        span {
          margin-right: 1rem;
        }
      }
    }
  }

  .service_profile {
    padding: 2rem;
    flex: 1;
    .service_box {
      padding: 1rem;
      min-height: 550px;
      .service-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
        .circle-btn {
          width: 35px;
          height: 35px;
          background: $buttons;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
        }
      }
      h3 {
      }
    }
  }
}

@media (max-width: 1024px) {
  .back {
    margin-left: 0.3rem;
    cursor: pointer;
    padding: 1rem;
    svg {
      font-size: 25px;
    }
  }

  .content {
    display: block;
    width: 100%;
    height: calc(100vh - 100px);

    .profile {
      padding: 0.3rem;
      display: flex;
      justify-content: center;
      .profile-box {
        width: 100%;
        min-width: 300px;
        max-width: 400px;
        height: 550px;
        @include box;
        .profile-avatar {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 2rem;
        }
        .name {
          width: 100%;
          text-align: center;
          margin-top: 1rem;
        }
        .location {
          @include profile;
          color: gray;
        }
        .member {
          @include profile;
          color: gray;
        }
        .phone {
          @include profile;
        }
        .edit_btn {
          @include button;
          span {
            margin-right: 1rem;
          }
        }
      }
    }

    .service_profile {
      margin-top: 1rem;
      padding: 0;

      .service_box {
        padding: 0.3rem;
        min-height: 550px;
        .service-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 1rem;
          .circle-btn {
            width: 35px;
            height: 35px;
            background: $buttons;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
          }
        }
        h3 {
        }
      }
    }
  }
}
