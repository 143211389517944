.auth-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 2rem);
  .auth-container {
    width: 450px;
    .logo {
      text-transform: uppercase;
      margin-bottom: 1.5rem;

      span {
        color: $buttons;
      }
    }
  }
}

@media (max-width: 724px) {
  .auth-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 1rem;
    .auth-container {
      width: 100%;
    }
    .form-page {
      width: 100%;
    }
  }
}
