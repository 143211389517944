.form-page {
  h3 {
    color: $buttons;
  }
  width: 400px;
  .input-control {
    width: 100%;
    margin-bottom: 0.6rem;
    input,
    textArea {
      width: 100%;
      padding: 1rem;
      border-radius: 8px;
      border: 2px solid #eee;
      color: rgb(95, 95, 95);
      outline: none;
    }
  }
  .input-date {
    width: 100%;
    margin-bottom: 0.6rem;
    .old {
      margin-top: 0.5rem;
      color: $buttons;
      span {
        margin-left: 0.6rem;
      }
    }
  }

  .form-btn {
    @include button;
    margin-top: 1rem;
    outline: none;
    svg {
      color: white;
    }
    span {
      margin-right: 1rem;
    }
  }

  .react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border-radius: 8px;
    border: 2px solid #eee;
  }

  .react-date-picker__inputGroup__input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
    color: rgb(95, 95, 95);
  }
}

@media screen and (max-width: 600px) {
  .form-page {
    width: 100%;
    .input-control {
      width: 100%;
      margin-bottom: 0.6rem;
      input,
      textArea {
        width: 100%;
        padding: 1rem;
        border-radius: 8px;
        border: 2px solid #eee;
        color: rgb(95, 95, 95);
        outline: none;
      }
    }
    .input-date {
      width: 100%;
      margin-bottom: 0.6rem;
    }

    .form-btn {
      @include button;
      margin-top: 1rem;
      outline: none;
      svg {
        color: white;
      }
      span {
        margin-right: 1rem;
      }
    }

    .react-date-picker__wrapper {
      display: flex;
      flex-grow: 1;
      flex-shrink: 0;
      border-radius: 8px;
      border: 2px solid #eee;
    }

    .react-date-picker__inputGroup__input {
      min-width: 0.54em;
      height: 100%;
      position: relative;
      padding: 0 1px;
      border: 0;
      background: none;
      font: inherit;
      box-sizing: content-box;
      -moz-appearance: textfield;
      color: rgb(95, 95, 95);
    }
  }
}
