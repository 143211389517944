@mixin box {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  overflow: visible;
  border-radius: 2px;
  padding: 1rem;
}

@mixin button {
  width: 100%;
  height: 70px;
  background-color: $buttons;
  overflow: visible;
  border-radius: 3px;
  margin-top: 3rem;
  color: white;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
}

@mixin circle($w, $h) {
  width: $w;
  height: $h;
  background: #7bb6cf;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
}
