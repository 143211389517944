.filter-btns {
  width: 100%;
}

.btn-flat {
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.bar-btns {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 30px;
  h6 {
    margin-bottom: 0;
  }
}

.mini-dot {
  width: 10px;
  height: 10px;
  background: $buttons;
  border-radius: 50%;
}

.tracker {
  display: flex;
  align-items: center;
  svg {
    color: $icons;
  }
  span {
    font-size: 1.5rem;
    font-weight: 600;
    margin-left: 0.4rem;
  }
}

@media screen and (max-width: 600px) {
  .filter-btns {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }

  .btn-flat {
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    margin: 0 !important;
  }

  .bar-btns {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 30px;
    h6 {
      margin-bottom: 0;
    }
  }

  .mini-dot {
    width: 10px;
    height: 10px;
    background: $buttons;
    border-radius: 50%;
  }

  .tracker {
    display: flex;
    align-items: center;
    svg {
      color: $icons;
    }
    span {
      font-size: 1.5rem;
      font-weight: 600;
      margin-left: 0.4rem;
    }
  }
}
