@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
@import "theme";
@import "mixins";
@import "toast";
@import "auth";
@import "toolbar";
@import "bar";
@import "customers";
@import "customer";
@import "service_list";
@import "services";
@import "./modals/modal_page";
@import "forms";
@import "account";
@import "overview";
@import "loader";
@import "calendar";
@import "menu";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  background: rgb(248, 248, 248);
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  overflow-x: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
}
button {
  outline: none;
}
hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(128, 128, 128, 0),
    rgba(190, 190, 190, 0.75),
    rgba(0, 0, 0, 0)
  );
  margin-bottom: 1rem;
}

a {
  text-decoration: none;
  color: $buttons;
}
ul {
  list-style-type: none;
}

.page {
  padding: 1rem;
}
.sub_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 1rem 0;
  h2 {
    margin-left: 1rem;
  }
}

.title {
  font-size: 18px;
  color: rgb(45, 126, 158);
  flex: 1;
  text-align: left;
}

.date {
  font-size: 12px;
  color: gray;
  margin-left: 0.1rem;
}
.number {
  margin-left: 0.4rem;
  font-size: 20px;
  text-align: right;
}
.phone {
  color: gray;
  margin-left: 0.5rem;
}

a {
  text-decoration: none;
}

.pending {
  color: $warning;
}
.complete {
  color: $complete;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cursor {
  cursor: pointer;
}

.circle-icon {
  margin-right: 1rem;
  width: 40px;
  height: 40px;
  background: #7bb6cf;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
}
.lrg-circle {
  @include circle(40px, 40px);
  .number {
    margin: 0;
  }
}

.btn-filter {
  cursor: pointer;
  @include button;
  height: 40px;
  margin-top: 0;
  margin-bottom: 1rem;
}

//common classes
.mt {
  margin-top: 1rem;
}
.mb {
  margin-bottom: 1rem;
}

//react select package
.css-yk16xz-control {
  background-color: hsl(0, 0%, 100%);
  border: none !important;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

.form-errors {
  color: $errors;
  margin: 5px 0 0 3px;
  font-size: 13px;
}

@media screen and (max-width: 724px) {
  .page {
    padding: 0;
  }
}
