.service {
  display: flex;
  width: 100%;
  justify-content: space-between;
  @include box;

  margin-bottom: 1.5rem;
  .service-info {
    display: flex;
    align-items: center;
    .price {
      font-size: 13px;
      color: $large_numbers;
    }
    .notes {
      font-size: 13px;
      color: $text;
    }
  }
  .avatar {
    margin-right: 1rem;
    width: 40px;
    height: 40px;
    background: #7bb6cf;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    min-width: 40px !important;
  }

  .status {
    min-width: 78px;
    .icon {
      span {
        cursor: pointer;
      }
      text-align: right;
      span:first-child {
        margin-right: 1rem;
      }
      span:nth-child(2) {
        margin-right: 1rem;
      }
    }
  }
}

//job-list
.job-list {
  .service {
    box-shadow: none;
    background-color: rgb(248, 248, 248);
    overflow: visible;
    border-radius: 0;
    margin-bottom: 0.1rem;
    padding: 0.3rem;
    h3 {
      margin: 0;
      color: $name;
    }

    .detail {
      .detail-btn {
        cursor: pointer;
        background: $buttons;
        padding: 0.3rem;
        border-radius: 30px;
        color: white;
        width: 75px;
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .service {
    display: block;
    width: 100%;
    justify-content: space-between;
    @include box;

    margin-bottom: 1.5rem;
    .service-info {
      display: flex;
      align-items: center;
      width: 100%;
      .service-name {
        width: 100%;
      }
      .price {
        font-size: 13px;
        color: $large_numbers;
      }
      .notes {
        font-size: 13px;
        color: $text;
        margin-top: 1rem;
      }
    }
    .avatar {
      margin-right: 0.3rem;
      width: 30px;
      height: 30px;
      background: #7bb6cf;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: white;
      min-width: 30px !important;
      display: none;
    }

    .status {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1rem;
      h5 {
        margin: 0;
      }
      .icon {
        span {
          cursor: pointer;
        }
        text-align: right;
        span:first-child {
          margin-right: 1rem;
        }
        span:nth-child(2) {
          margin-right: 1rem;
        }
      }
    }
  }

  //job-list
  .job-list {
    .service {
      box-shadow: none;
      background-color: rgb(248, 248, 248);
      overflow: visible;
      border-radius: 0;
      margin-bottom: 0.1rem;
      padding: 0.3rem;
      h3 {
        margin: 0;
        color: $name;
      }

      .detail {
        .detail-btn {
          cursor: pointer;
          background: $buttons;
          padding: 0.3rem;
          border-radius: 30px;
          color: white;
          width: 75px;
          margin-top: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
